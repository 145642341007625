import React from 'react'

export default function Homevalue() {
    return (
        <div>
            {/* <!-- Start Value Area --> */}
        <section className="value-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="value-image">
                            <img src="assets/img/value/value-1.png" alt="image"/>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="value-item">
                            <div className="value-content">
                                <span>Our Core Values</span>
                                <h3>We are Refunding Early Childcare Education</h3>
                            </div>

                            <div className="value-inner-content">
                                <div className="number">
                                    <span>01</span>
                                </div>
                                <h4>Active Learning</h4>
                                <p style={{textAlign: "justify"}}>Neoterics follows the Neo-Montessori system for early education. This system successfully blends all the essential elements of Montessori education into a thematic learning environment. In addition, our philosophy caters to the Multiple Intelligences Theory and Habits of Mind Framework.</p>
                            </div>

                            <div className="value-inner-content">
                                <div className="number">
                                    <span className="bg-2">02</span>
                                </div>
                                <h4>Healthy Environment</h4>
                                <p style={{textAlign: "justify"}}>We believe that health is the biggest blessing on earth. Hence, we place extra emphasis on ensuring that our children are provided with a healthy environment..</p>
                            </div>

                            <div className="value-inner-content">
                                <div className="number">
                                    <span className="bg-3">03</span>
                                </div>
                                <h4>Fully Equipped</h4>
                                <p style={{textAlign: "justify"}}>Neoterics Preschool classrooms offer situations of adventure and discovery to every young explorer. Learning materials that develop self-expression and pre-academic skills are arranged creatively.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="value-shape">
                <div className="shape-1">
                    <img src="assets/img/value/value-shape-1.png" alt="image"/>
                </div>
                <div className="shape-2">
                    <img src="assets/img/value/value-shape-2.png" alt="image"/>
                </div>
                <div className="shape-3">
                    <img src="assets/img/value/value-shape-3.png" alt="image"/>
                </div>
            </div>
        </section>
        {/* <!-- End Value Area --> */}
        </div>
    )
}
