import React from 'react'



import Homebanner       from './components/Homebanner'
import Homeintro        from './components/Homeintro'
import Homeclass        from './components/Homeclass'
import Homevalue        from './components/Homevalue'
import Hometeacher      from './components/Hometeacher'
import Testimonials     from './components/Testimonials'
import Homeevents       from './components/Homeevents'
import Homeblog         from './components/Homeblog'


export default function Home() {
    return (
        <div>
            <Homebanner/>
            <Homeintro/>
            <Homeclass/>
            <Homevalue/>
            <Hometeacher/>
            {/* <Hometestimonial/> */}
            <Testimonials/>
            <Homeevents/>
            <Homeblog/>
        </div>
    )
}