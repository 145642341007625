import React from 'react'

import PageBanner       from './components/PageBanner'
import ContactArea      from './components/ContactArea'
import Map              from './components/Map'

export default function Contact() {
    return (
        <div>
            <PageBanner name="Contact Us" />
            <ContactArea/>
            <Map/>
        </div>
    )
}