import React from 'react'

export default function Newsletter() {
    return (
        <div className="newsletter-area ptb-100">
            {/* <!-- Start Newsletter Area --></div> */}
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="newsletter-content">
                            <h2>Do You Want To Know Get Update What’s Upcoming</h2>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <form className="newsletter-form">
                            <input type="email" className="input-newsletter" placeholder="Enter Email Address" name="EMAIL" required autocomplete="off"/>

                            <button type="submit">Subscribe Now</button>
                            
                            <div id="validator-newsletter" className="form-result"></div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="newsletter-shape">
                <div className="shape-1">
                    <img src="/assets/img/newsletter/newsletter-shape-1.png" alt="image"/>
                </div>
                <div className="shape-2">
                    <img src="/assets/img/newsletter/newsletter-shape-2.png" alt="image"/>
                </div>
            </div>
            {/* <!-- End Newsletter Area --> */}
        </div>
    )
}
