import React from 'react'

export default function ContactArea() {
    return (
        // <!-- Start Contact Area -->
        <section className="contact-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12">
                        <div className="contact-form">
                            <h3>Ready to Get Started?</h3>

                            <form id="contactForm">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="name" id="name" className="form-control" required data-error="Please enter your name" placeholder="Your name"/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="email" id="email" className="form-control" required data-error="Please enter your email" placeholder="Your email address"/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="phone_number" id="phone_number" className="form-control" required data-error="Please enter your phone number" placeholder="Your phone number"/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <input type="text" name="subjects" id="subjects" className="form-control" required data-error="Please enter your subjects" placeholder="Subjects"/>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" id="message" cols="30" rows="5" required data-error="Please enter your message" className="form-control" placeholder="Write your message..."></textarea>
                                            <div className="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn">Send Message</button>
                                        <div id="msgSubmit" className="h3 text-center hidden"></div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="col-lg-5 col-md-12">
                        <div className="contact-information">
                            <h3>Here to Help</h3>

                            <ul className="contact-list">
                                <li><i className='bx bx-map'></i> Location: <span>Hamdard Chowk,Township, Lahore, Pakistan</span></li>
                                <li><i className='bx bx-phone-call'></i> Call Us: <a href="tel:0123-456-789">0123-456-789</a></li>
                                <li><i className='bx bx-envelope'></i> Email Us: <a href="mailto:info@neoterics.edu.pk">info@neoterics.edu.pk</a></li>
                                <li><i className='bx bx-microphone'></i> Fax: <a href="tel:0123-456-789">0123-456-789</a></li>
                            </ul>

                            <h3>Opening Hours:</h3>
                            <ul className="opening-hours">
                                <li><span>Monday:</span> 8AM - 6AM</li>
                                <li><span>Tuesday:</span> 8AM - 6AM</li>
                                <li><span>Wednesday:</span> 8AM - 6AM</li>
                                <li><span>Thursday:</span> 8AM - 6AM</li>
                                <li><span>Friday:</span> 8AM - 6AM</li>
                                <li><span>Saturday:</span> Closed</li>
                                <li><span>Sunday:</span> Closed</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
        ///* <!-- End Contact Area --> */
    )
}
