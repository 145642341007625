import React from 'react'

import PageBanner       from './components/PageBanner'
import Quote            from './components/Quote'
import ContactArea      from './components/ContactArea'
import Newsletter       from './components/Newsletter'

export default function Apply() {
    return (
        <div>
            <PageBanner name="Apply Now" />
            {/* <ContactArea/> */}
            <div className="col-md-8 offset-2">
                <div className="container">
                    <div className="quote-item">
                        <div className="content">
                            <span>Get a Quote</span>
                            <h3>Online Class Registration</h3>
                        </div>

                        <form >
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Your Name"/>
                            </div>

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Email Address"/>
                            </div>

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Studying Class"/>
                            </div>

                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Type Your Requirements"/>
                            </div>
                            
                            <button type="submit" className="default-btn">
                                Submit Now
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            {/* <Quote/> */}
            <Newsletter/>
        </div>
    )
}