import React from "react";

import {     // BrowserRouter as Router,
    Link
} from 'react-router-dom'
const scripts = [
    'assets/js/jquery.min.js',
    'assets/js/popper.min.js',
    'assets/js/bootstrap.min.js', 
    'assets/js/jquery.meanmenu.js',
    'assets/js/imagelightbox.min.js', 
    'assets/js/jquery.magnific-popup.min.js', 
    'assets/js/owl.carousel.min.js',
    'assets/js/odometer.min.js', 
    'assets/js/jquery.appear.min.js', 
    'assets/js/jquery.ajaxchimp.min.js',
    'assets/js/form-validator.min.js', 
    'assets/js/contact-form-script.js', 
    'assets/js/main.js'
];

export default function Homebanner() {
  

  
const jsHandler = (innerHtml) => {

    let elements = document.getElementsByClassName("scripts");
    while(elements.length > 0){
        elements[0].parentNode.removeChild(elements[0]);
    }

    const container = document.createElement('div');
    container.className = "scripts";
    innerHtml.forEach(element => {
        const script = document.createElement("script");
        script.src = element;
        script.async = false;
        container.appendChild(script);
        // console.log(element);
    });
    document.body.appendChild(container);
  }

  return (
    <div>
            

            {/* <!-- Start Main Banner Area --> */}
        <div className="main-banner"> 
            <div className="main-banner-item">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="main-banner-content">
                                        <span>Play, Learn and Grow</span>
                                        <h1>Neoterics School System </h1>
                                        <p>Modern Cyber Schools Network Of Pakistan</p>
                                        

                                        <div className="banner-btn">
                                            <Link to="/apply-now" className="default-btn" onClick={() => jsHandler(scripts)} >
                                                Apply Now
                                            </Link>
                                            <Link to="/about" className="optional-btn" onClick={() => jsHandler(scripts)} >
                                                Find Out More
                                            </Link>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="main-banner-image">
                                        <img src="assets/img/main-banner/education-girl.png" alt="image"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-banner-shape">
                <div className="banner-bg-shape">
                    <img src="assets/img/main-banner/banner-bg-shape-1.png" alt="image"/>
                </div>

                <div className="shape-1">
                    <img src="assets/img/main-banner/banner-shape-1.png" alt="image"/>
                </div>

                <div className="shape-2">
                    <img src="assets/img/main-banner/banner-shape-2.png" alt="image"/>
                </div>

                <div className="shape-3">
                    <img src="assets/img/main-banner/banner-shape-3.png" alt="image"/>
                </div>

                <div className="shape-4">
                    <img src="assets/img/main-banner/banner-shape-4.png" alt="image"/>
                </div>
            </div>
        </div>
        {/* <!-- End Main Banner Area --> */}
        </div>
  );
}
