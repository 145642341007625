import React from 'react'

export default function Homeclass() {
    return (
        <div>
            
            {/* <!-- Start Class Area --> */}
        <section className="class-area bg-fdf6ed pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span>Services</span>
                    <h2>Our Services</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-class">
                            <div className="class-image">
                                <a href="#">
                                    <img src="assets/img/class/class-1.jpg" alt="image"/>
                                </a>
                            </div>

                            <div className="class-content">
                                {/* <div className="price">$880</div> */}
                                <h3>
                                    <a href="#">Franchise</a>
                                </h3>
                                <p style={{textAlign: "justify"}}>Joining our network as franchisee is better suited for people who are: running schools, tuition centres, are in-service or retired school teachers, retired civil military officers, expatriate Pakistani's willing to invest  long-term, and property owners looking for a worthy business opportunity.</p>

                                {/* <ul className="class-list">
                                    <li>
                                        <span>Age:</span>
                                        3-5 Year
                                    </li>
                                    <li>
                                        <span>Time:</span>
                                        8-10 AM
                                    </li>
                                    <li>
                                        <span>Seat:</span>
                                        25
                                    </li>
                                </ul> */}

                                {/* <div className="class-btn">
                                    <a href="#" className="default-btn">Read More</a>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-class">
                            <div className="class-image">
                                <a href="#">
                                    <img src="assets/img/class/class-2.jpg" alt="image"/>
                                </a>
                            </div>

                            <div className="class-content">
                                {/* <div className="price">$790</div> */}
                                <h3>
                                    <a href="#">Pre School</a>
                                </h3>
                                <p style={{textAlign: "justify"}}>The Pre-school was launched on 24th oct and today there are over 60 branches across Pakistan. The curriculum is based on active forms of learning, on stimulating a curious mind, on discovering, and learning is experiential (learning-by-doing) rather than learning that is based exclusively on books.</p>

                                {/* <ul className="class-list">
                                    <li>
                                        <span>Age:</span>
                                        3-5 Year
                                    </li>
                                    <li>
                                        <span>Time:</span>
                                        8-10 AM
                                    </li>
                                    <li>
                                        <span>Seat:</span>
                                        25
                                    </li>
                                </ul> */}

                                {/* <div className="class-btn">
                                    <a href="#" className="default-btn">Read More</a>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="single-class">
                            <div className="class-image">
                                <a href="#">
                                    <img src="assets/img/class/class-3.jpg" alt="image"/>
                                </a>
                            </div>

                            <div className="class-content">
                                {/* <div className="price">$590</div> */}
                                <h3>
                                    <a href="#">Parent's CORNER</a>
                                </h3>
                                <p style={{textAlign: "justify"}}>We endeavor to ensure that all the various elements necessary for a well-rounded education and opportunities to excel are within reach of students. The Educators provides an environment that encourages learning and facilities to enhance and support students’ education.</p>

                                {/* <ul className="class-list">
                                    <li>
                                        <span>Age:</span>
                                        3-5 Year
                                    </li>
                                    <li>
                                        <span>Time:</span>
                                        8-10 AM
                                    </li>
                                    <li>
                                        <span>Seat:</span>
                                        25
                                    </li>
                                </ul> */}

                                {/* <div className="class-btn">
                                    <a href="#" className="default-btn">Read More</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="class-shape">
                <div className="shape-1">
                    <img src="assets/img/class/class-shape-1.png" alt="image"/>
                </div>
                <div className="shape-2">
                    <img src="assets/img/class/class-shape-2.png" alt="image"/>
                </div>
            </div>
        </section>
        {/* <!-- End Class Area --> */}


        </div>
    )
}
