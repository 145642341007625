import React,{useState,useEffect} from 'react'
import {Link} from 'react-router-dom'
const scripts = [
    '/assets/js/jquery.min.js',
    '/assets/js/popper.min.js',
    '/assets/js/bootstrap.min.js', 
    '/assets/js/jquery.meanmenu.js',
    '/assets/js/imagelightbox.min.js', 
    '/assets/js/jquery.magnific-popup.min.js', 
    '/assets/js/owl.carousel.min.js',
    '/assets/js/odometer.min.js', 
    '/assets/js/jquery.appear.min.js', 
    '/assets/js/jquery.ajaxchimp.min.js',
    '/assets/js/form-validator.min.js', 
    '/assets/js/contact-form-script.js', 
    '/assets/js/main.js'
];
export default function Homeevents() {
    const jsHandler = (innerHtml) => {

        let elements = document.getElementsByClassName("scripts");
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }

        const container = document.createElement('div');
        container.className = "scripts";
        innerHtml.forEach(element => {
            const script = document.createElement("script");
            script.src = element;
            script.async = false;
            container.appendChild(script);
            // console.log(element);
        });
        document.body.appendChild(container);
    }

    const [data,setData]=useState([]);
    const getData=()=>{
        fetch('/data/events.json'
        ,{
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        }
        )
        .then(function(response){
            console.log(response)
            return response.json();
        })
        .then(function(myJson) {
            console.log(myJson);
            setData(myJson)
        });
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        <div>
            {/* <!-- Start Event Area --> */}
        <section className="event-area bg-ffffff pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <span>Events</span>
                    <h2>Upcoming Events</h2>
                </div>
        {
            
                data && data.length>0 && data.slice(0, 2).map(item => 
                
                <div key={item.eventID.toString()}   className="event-box-item">
                    
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <div className="event-image">
                                <Link to={"/event-details/"+item.eventID} onClick={() => jsHandler(scripts)}>
                                    <img className="img-circle" src={"/assets/img/event/"+item.image} alt="image"/>
                                </Link>
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="event-content">
                                <h3>
                                    <Link to={"/event-details/"+item.eventID} onClick={() => jsHandler(scripts)}>{item.title}</Link>
                                </h3>
                                <ul className="event-list">
                                    <li>
                                        <i className='bx bx-calendar'></i>
                                        {item.date}
                                    </li>
                                    <li>
                                        <i className='bx bxs-map'></i>
                                        {item.location}
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="event-date">
                                <h4>{item.dateDay}</h4>
                                <span>{item.day}</span>
                            </div>
                        </div>
                    </div>
                </div>
                )
                
                
         
        }

            </div>
        </section>
        {/* <!-- End Event Area --> */}
        </div>
    )
}
