import React from 'react'

export default function Testimonials() {
    return (
        <div>
            {/* <!-- Start Testimonials Area --> */}
        <section className="testimonials-area bg-color ptb-100">
            <div className="container">
                <div className="section-title">
                    <span>Testimonials</span>
                    <h2>What Parents Say About Us</h2>
                </div>

                <div className="feedback-slides owl-carousel owl-theme">
                    <div className="feedback-item">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="feedback-image">
                                    <img src="assets/img/testimonials/testimonials-4.jpg" />
                                </div>
                            </div>

                            <div className="col-lg-8">
                                <div className="feedback-content">
                                    <p>During my childhood, I was enrolled in a Montessori program for my pre-school. I sll remember the excitement and enjoyment while using an array of specialized Montessori materials to boost learning.</p>

                                    <div className="feedback-info">
                                        <h3>Muhammad Ali Khan</h3>
                                        {/* <span>CEO</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="feedback-item">
                        <div className="row align-items-center">
                            <div className="col-lg-4">
                                <div className="feedback-image">
                                    <img src="assets/img/testimonials/testimonials-5.jpg" />
                                </div>
                            </div>

                            <div className="col-lg-8">
                                <div className="feedback-content">
                                    <p>Since all parents want the very best for their children, a right quality first learning experience is crucial for their intellectual development. At the same me, children also need to socialise, play, have fun and enjoy the structured/balanced/progressive learning experience..</p>

                                    <div className="feedback-info">
                                        <h3>Neelofar Gilani</h3>
                                        {/* <span>Principal</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End Testimonials Area --> */}
        </div>
    )
}
