import React from 'react'
import { // BrowserRouter as Router,
            Link
        } from 'react-router-dom'
const scripts = [
    'assets/js/jquery.min.js',
    'assets/js/popper.min.js',
    'assets/js/bootstrap.min.js', 
    'assets/js/jquery.meanmenu.js',
    'assets/js/imagelightbox.min.js', 
    'assets/js/jquery.magnific-popup.min.js', 
    'assets/js/owl.carousel.min.js',
    'assets/js/odometer.min.js', 
    'assets/js/jquery.appear.min.js', 
    'assets/js/jquery.ajaxchimp.min.js',
    'assets/js/form-validator.min.js', 
    'assets/js/contact-form-script.js', 
    'assets/js/main.js'
];
export default function PageBanner(props) {

    const jsHandler = (innerHtml) => {

        let elements = document.getElementsByClassName("scripts");
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }

        const container = document.createElement('div');
        container.className = "scripts";
        innerHtml.forEach(element => {
            const script = document.createElement("script");
            script.src = element;
            script.async = false;
            container.appendChild(script);
            // console.log(element);
        });
        document.body.appendChild(container);
      }

    return (
        <div>
            {/* <!-- Start Page Banner --> */}
        <div className="page-banner-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="page-banner-content">
                            <h2>{props.name}</h2>
                            <ul>
                                <li>
                                    <Link to="/" className="nav-link" onClick={() => jsHandler(scripts)} >
                                        Home
                                    </Link>
                                </li>
                                <li>{props.name}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Page Banner --> */}
        </div>
    )
}
