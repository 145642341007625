import React,{useState,useEffect} from 'react'
import { 
    // BrowserRouter as Router,
    Link
   } from 'react-router-dom'
   const scripts = [
    '/assets/js/jquery.min.js',
    '/assets/js/popper.min.js',
    '/assets/js/bootstrap.min.js', 
    '/assets/js/jquery.meanmenu.js',
    '/assets/js/imagelightbox.min.js', 
    '/assets/js/jquery.magnific-popup.min.js', 
    '/assets/js/owl.carousel.min.js',
    '/assets/js/odometer.min.js', 
    '/assets/js/jquery.appear.min.js', 
    '/assets/js/jquery.ajaxchimp.min.js',
    '/assets/js/form-validator.min.js', 
    '/assets/js/contact-form-script.js', 
    '/assets/js/main.js'
];
export default function NewsArea() {

    const jsHandler = (innerHtml) => {

        let elements = document.getElementsByClassName("scripts");
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }

        const container = document.createElement('div');
        container.className = "scripts";
        innerHtml.forEach(element => {
            const script = document.createElement("script");
            script.src = element;
            script.async = false;
            container.appendChild(script);
            // console.log(element);
        });
        document.body.appendChild(container);
    }

    const [data,setData]=useState([]);
    const getData=()=>{
        fetch('./data/news.json'
        ,{
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        }
        )
        .then(function(response){
            console.log(response)
            return response.json();
        })
        .then(function(myJson) {
            console.log(myJson);
            setData(myJson)
        });
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        //<!-- Start Blog Area -->
        <section className="blog-area pt-100 pb-100">
            <div className="container">
                <div className="row">
                {
                    data && data.length>0 && data.map((item)=>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-item">
                            <div className="blog-image">
                                <Link to={"/details/"+item.newsID} onClick={() => jsHandler(scripts)}>
                                    <img src={"assets/img/cause/"+item.newsImage} alt="image"/>
                                </Link>
                            </div>

                            <div className="blog-content">
                                {/* <ul className="post-meta">
                                    <li>
                                        <span>By Admin:</span>
                                        <a href="#">Jack John</a>
                                    </li>
                                    <li>
                                        <span>Date:</span>
                                        25 Dec 2020
                                    </li>
                                </ul> */}
                                <h6>
                                    <Link to={"/details/"+item.newsID} onClick={() => jsHandler(scripts)}> {item.newsTitle}</Link>
                                </h6>
                                <p style={{textAlign: "justify"}}>{item.newsDescripition.substring(0, 80)}...</p>

                                <div className="blog-btn">
                                    <Link to={"/details/"+item.newsID} className="default-btn" onClick={() => jsHandler(scripts)}>Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                }
                </div>
            </div>
        </section>
        //<!-- End Blog Area -->
    )
}
