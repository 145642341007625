import React from 'react'

export default function Map() {
    return (
        // <!-- Map -->
        <div id="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.884347507338!2d74.31696694550445!3d31.444850716824583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391906cbd12a8403%3A0x568e8ad71dab1c6d!2sMinhaj+University+Lahore+-+Main+Campus!5e0!3m2!1sen!2s!4v1522669837974" width="600" height="450" frameborder="0"  allowfullscreen></iframe>

        </div>
        // <!-- End Map -->
    )
}
