import React from 'react';

import { 
    // BrowserRouter as Router,
    Link
   } from 'react-router-dom'

    const scripts = [
                        '/assets/js/jquery.min.js',
                        '/assets/js/popper.min.js',
                        '/assets/js/bootstrap.min.js', 
                        '/assets/js/jquery.meanmenu.js',
                        '/assets/js/imagelightbox.min.js', 
                        '/assets/js/jquery.magnific-popup.min.js', 
                        '/assets/js/owl.carousel.min.js',
                        '/assets/js/odometer.min.js', 
                        '/assets/js/jquery.appear.min.js', 
                        '/assets/js/jquery.ajaxchimp.min.js',
                        '/assets/js/form-validator.min.js', 
                        '/assets/js/contact-form-script.js', 
                        '/assets/js/main.js'
                    ];


export default function Topnavi() {


    const jsHandler = (innerHtml) => {

        let elements = document.getElementsByClassName("scripts");
        while(elements.length > 0){
            elements[0].parentNode.removeChild(elements[0]);
        }

        const container = document.createElement('div');
        container.className = "scripts";
        innerHtml.forEach(element => {
            const script = document.createElement("script");
            script.src = element;
            script.async = false;
            container.appendChild(script);
            // console.log(element);
        });
        document.body.appendChild(container);
      }

    return (
    
    <div>
        {/* <!-- Start Preloader Area --> */}
        {/* <div className="preloader">
            <div className="loader">
                <div className="wrapper">
                    <div className="circle circle-1"></div>
                    <div className="circle circle-1a"></div>
                    <div className="circle circle-2"></div>
                    <div className="circle circle-3"></div>
                </div>
            </div>
        </div> */}
        {/* <!-- End Preloader Area --> */}

        {/* <!-- Start Navbar Area --> */}
        
        <div className="navbar-area">
            <div className="main-responsive-nav">
                <div className="container">
                    <div className="main-responsive-menu">
                        <div className="logo">
                            <a href="">
                                <img src="/assets/img/logo.png" alt="image"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="main-navbar">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <a className="navbar-brand" href="">
                            <img src="/assets/img/logo.png" alt="image"/>
                        </a>

                        <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link to="/" className="nav-link" onClick={() => jsHandler(scripts)} >
                                        Home
                                    </Link>
                                </li>

                                <li className="nav-item" >
                                    <Link to="/about" className="nav-link" onClick={() => jsHandler(scripts)}>
                                        About
                                    </Link>

                                </li>
                                <li className="nav-item" >
                                    <Link to="/team" className="nav-link" onClick={() => jsHandler(scripts)}>
                                        Our Team
                                    </Link>

                                </li>

                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        News &amp; Events  
                                        <i className="bx bx-chevron-down"></i>
                                    </a>

                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            
                                              <Link to="/news" className="nav-link" onClick={() => jsHandler(scripts)}>
                                                News
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="/events" className="nav-link" onClick={() => jsHandler(scripts)}>
                                                Events
                                            </Link>
                                        </li>
                                    </ul>
                                </li>


                                <li className="nav-item" >
                                    <Link to="/contact" className="nav-link" onClick={() => jsHandler(scripts)}>
                                        Contact Us 
                                    </Link>

                                </li>

                                {/* <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        Event 
                                        <i className='bx bx-chevron-down'></i>
                                    </a>

                                    <ul className="dropdown-menu">
                                        <li className="nav-item">
                                            <a href="event.html" className="nav-link">
                                                Event
                                            </a>
                                        </li>

                                        <li className="nav-item">
                                            <a href="event-details.html" className="nav-link">
                                                Event Details
                                            </a>
                                        </li>
                                    </ul>
                                </li> */}

                               
                            </ul>

                            <div className="others-options d-flex align-items-center">
                                <div className="option-item">
                                    <Link to="/apply-now" className="default-btn" onClick={() => jsHandler(scripts)}>Apply Now </Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>

            <div className="others-option-for-responsive">
                <div className="container">
                    <div className="dot-menu">
                        <div className="inner">
                            <div className="circle circle-one"></div>
                            <div className="circle circle-two"></div>
                            <div className="circle circle-three"></div>
                        </div>
                    </div>
                    
                    <div className="container">
                        <div className="option-inner">
                            <div className="others-options d-flex align-items-center">

                                <div className="option-item">
                                    <Link to="/apply-now" className="default-btn" onClick={() => jsHandler(scripts)}>Apply Now </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Navbar Area --> */}
       </div>
       
    )
}
