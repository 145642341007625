import React from 'react'

import PageBanner       from './components/PageBanner'
import NewsArea       from './components/NewsArea'
import Newsletter       from './components/Newsletter'

export default function News() {
    return (
        <div>
            <PageBanner name="News" />
            <NewsArea/>
            <Newsletter/>
        </div>
    )
}