import React from 'react'

export default function Funfacts() {
    return (
        
        <section className="fun-facts-area pt-100 pb-70">
            {/* <!-- Start Fun Facts Area --> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-fun-fact">
                            <h3>
                                <span className="odometer" data-count="800">00</span>
                            </h3>
                            <p>Students</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-fun-fact bg-1">
                            <h3>
                                <span className="odometer" data-count="100">00</span>
                            </h3>
                            <p>Teachers</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-fun-fact bg-2">
                            <h3>
                                <span className="odometer" data-count="50">00</span>
                            </h3>
                            <p>Classroom</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="single-fun-fact bg-3">
                            <h3>
                                <span className="odometer" data-count="50">00</span>
                            </h3>
                            <p>Bus</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Fun Facts Area --> */}
        </section>
        
    )
}
