import React from 'react'

export default function Preloader() {
    return (
        
             
        //<!-- Start Preloader Area -->
        <div className="preloader">
            <div className="loader">
                <div className="wrapper">
                    <div className="circle circle-1"></div>
                    <div className="circle circle-1a"></div>
                    <div className="circle circle-2"></div>
                    <div className="circle circle-3"></div>
                </div>
            </div>
        </div>
        //<!-- End Preloader Area -->
        
        
    )
}
