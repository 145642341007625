import React,{useState,useEffect} from 'react'

export default function TeamsArea() {
    const [data,setData]=useState([]);
    const getData=()=>{
        fetch('./data/testimonial.json'
        ,{
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        }
        )
        .then(function(response){
            // console.log(response)
            return response.json();
        })
        .then(function(myJson) {
            //console.log(myJson);
            setData(myJson)
        });
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        //<!-- Start Teacher Area -->
        <section className="teacher-area pt-100 pb-70">
            <div className="container-fluid">
                <div className="row">
                {
                    data && data.length>0 && data.map((item)=>
                        <div className="col-lg-3 col-md-6">
                        <div className="single-teacher">
                            <div className="image">
                                <img src={'assets/img/testimonial/'+item.testimonialImage} />

                                <ul className="social">

                                    <li>
                                        <a href="#" target="_blank">
                                            <i className='bx bxl-facebook'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i className='bx bxl-twitter'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i className='bx bxl-linkedin'></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <i className='bx bxl-instagram'></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="content">
                                <h3>{item.testimonialName}</h3>
                                <span>{item.testimonialDesignation}</span>
                            </div>
                        </div>
                    </div>
                    )
                }
                </div>
            </div>
        </section>
        //<!-- End Teacher Area -->
    )
}
