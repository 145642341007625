import React from 'react'

export default function Teacher() {
    return (
        
        <section className="teacher-area pt-100 pb-70">
            {/* <!-- Start Teacher Area --> */}
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-teacher-content">
                            <span>Expert Teacher</span>
                            <h3>
                                <a href="#">Montessori Section</a>
                            </h3>
                            <p>We established a board of Montessori experts to improve the learning of preschoolers and toddlers. The LHIS head office is also providing comprehensive Montessori kits along with training sessions. Our Montessori experts teach and train your staff, with the help of the Montessori kits, so that they might educate young learners in the best possible manner.</p>

                            {/* <div className="teacher-btn">
                                <a href="#" className="default-btn">Instructor Profile</a>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-teacher-item">
                            <div className="image">
                                <img src="assets/img/teacher/teacher-8.jpg" alt="image"/>

                                <div className="content">
                                    <h3>Miss Nasreen Pervaiz</h3>
                                    <span>History Teacher</span>
    
                                    <ul className="social">
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-facebook'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-twitter'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-google-plus'></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-teacher-item">
                            <div className="image">
                                <img src="assets/img/teacher/teacher-9.jpg" alt="image"/>

                                <div className="content">
                                    <h3>Shahid Afzal</h3>
                                    <span></span>
    
                                    <ul className="social">
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-facebook'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-twitter'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-google-plus'></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-teacher-item">
                            <div className="image">
                                <img src="assets/img/teacher/teacher-10.jpg" alt="image"/>

                                <div className="content">
                                    <h3>Khadija Usman</h3>
                                    <span>Math Teacher</span>
    
                                    <ul className="social">
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-facebook'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-twitter'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-google-plus'></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-teacher-item">
                            <div className="image">
                                <img src="assets/img/teacher/teacher-11.jpg" alt="image"/>

                                <div className="content">
                                    <h3>Khurram Shahzad</h3>
                                    <span>English Teacher</span>
    
                                    <ul className="social">
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-facebook'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-twitter'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-google-plus'></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-teacher-item">
                            <div className="image">
                                <img src="assets/img/teacher/teacher-12.jpg" alt="image"/>

                                <div className="content">
                                    <h3>Maria Umar</h3>
                                    <span>Literacy Teacher</span>
    
                                    <ul className="social">
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-facebook'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-twitter'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className='bx bxl-google-plus'></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Teacher Area --> */}
        </section>
        
    )
}
