import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const scripts = [
  'assets/js/jquery.min.js',
  'assets/js/popper.min.js',
  'assets/js/bootstrap.min.js', 
  'assets/js/jquery.meanmenu.js',
  'assets/js/imagelightbox.min.js', 
  'assets/js/jquery.magnific-popup.min.js', 
  'assets/js/owl.carousel.min.js',
  'assets/js/odometer.min.js', 
  'assets/js/jquery.appear.min.js', 
  'assets/js/jquery.ajaxchimp.min.js',
  'assets/js/form-validator.min.js', 
  'assets/js/contact-form-script.js', 
  'assets/js/main.js'
];

const jsHandler = (innerHtml) => {
  const container = document.createElement('div');
  container.className = "scripts";
  innerHtml.forEach(element => {
      const script = document.createElement("script");
      script.src = element;
      script.async = false;
      container.appendChild(script);
      // console.log(element);
  });
  document.body.appendChild(container);
  
}

ReactDOM.render(
  <React.StrictMode>
    <App />
    {/* <InnerHTML html={html} /> */}
  </React.StrictMode>,
  document.getElementById('root'),
  jsHandler(scripts)
  // document.body.appendChild(script)
  
  // document.getElementById('root').append()
);
// ReactDOM.render(<App />, document.getElementById('app'));


reportWebVitals();
