import React from 'react'

import { useLocation } from 'react-router-dom'

export default function Header() {

    // let { url } = useRouteMatch();
    // let { params } = useParams();
    let { pathname } = useLocation();

        return (
            <head>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
                <title>
                    {pathname}
                </title>
                <link rel="icon" type="image/png" href="/assets/img/favicon.png"/>
                {/* <!-- Bootstrap CSS -->  */}
                <link rel="stylesheet" href="/assets/css/bootstrap.min.css"/>
                {/* <!-- Animate CSS -->  */}
                <link rel="stylesheet" href="/assets/css/animate.min.css"/>
                {/* <!-- Meanmenu CSS --> */}
                <link rel="stylesheet" href="/assets/css/meanmenu.css"/>
                {/* <!-- Boxicons CSS --> */}
                <link rel="stylesheet" href="/assets/css/boxicons.min.css"/>
                {/* <!-- Owl Carousel CSS --> */}
                <link rel="stylesheet" href="/assets/css/owl.carousel.min.css"/>
                {/* <!-- Owl Carousel Default CSS --> */}
                <link rel="stylesheet" href="/assets/css/owl.theme.default.min.css"/>
                {/* <!-- Odometer CSS --> */}
                <link rel="stylesheet" href="/assets/css/odometer.min.css"/>
                {/* <!-- Magnific Popup CSS --> */}
                <link rel="stylesheet" href="/assets/css/magnific-popup.min.css"/>
                {/* <!-- Imagelightbox CSS --> */}
                <link rel="stylesheet" href="/assets/css/imagelightbox.min.css"/>
                {/* <!-- Style CSS --> */}
                <link rel="stylesheet" href="/assets/css/style.css"/>
                {/* <!-- Responsive CSS --> */}
                <link rel="stylesheet" href="/assets/css/responsive.css"/>
            </head>
        )
    
    
    
}
