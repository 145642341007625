import React from 'react'

import PageBanner       from './components/PageBanner'

import Aboutintro from './components/Aboutintro'
import Funfacts from './components/Funfacts'
import Teacher from './components/Teacher'
import Quote from './components/Quote'
import Request from './components/Request'
import Testimonials from './components/Testimonials'
// import Pricing from './components/Pricing'
import Newsletter from './components/Newsletter'
import Partner from './components/Partner'

export default function About() {
    return (
        <div>
            <PageBanner name="About" />
            <Aboutintro/>
            <Funfacts/>
            <Teacher/>
            <Quote/>
            <Request/>
            <Testimonials/>
            {/* <Pricing/> */}
            <Newsletter/>
            {/* <Partner/> */}
            
        </div>
    )
}