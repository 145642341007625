import React,{useState,useEffect} from 'react'
import { 
    // BrowserRouter as Router,
    useParams
   } from 'react-router-dom'

import PageBanner       from './components/PageBanner'
import EventsDetail         from './components/EventsDetail'
import Newsletter       from './components/Newsletter'
import Homeblog         from './components/Homeblog'

export default function EventDetail() {
    let { id } = useParams();
    const [data,setData]=useState([]);
    const getData=()=>{
        fetch('/data/events.json'
        ,{
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        }
        )
        .then(function(response){
            // console.log(response)
            return response.json();
        })
        .then(function(myJson) {
            // console.log("id"+id);
            let selectedObject = filterById(myJson, id);
            // console.log( selectedObject);
            setData(selectedObject)
        });
    }
    useEffect(()=>{
        getData()
    },[])
    function filterById(jsonObject, id) {return jsonObject.filter(function(jsonObject) {return (jsonObject['eventID'] == id);})[0];}
    return (
        <div>
            <PageBanner name={data.title} />
            <EventsDetail data={data}/>
            <Homeblog/>
            <Newsletter/>
        </div>
    )
}