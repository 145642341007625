import React from 'react'


export default function NewsDetail(props) {
    let data = props.data;

    return (
        
        <section className="blog-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        
                    {   
                        
                        <div className="blog-details-desc">
                            <h3>{data.newsTitle}</h3>
                            <div className="article-image">
                                <img src={"/assets/img/cause/" + data.newsImage} />
                            </div>

                            <div className="article-content">
                                {/* <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <span>Posted On:</span> 
                                            <a href="#">September 31, 2020</a>
                                        </li>
                                        <li>
                                            <span>Posted By:</span> 
                                            <a href="#">John Anderson</a>
                                        </li>
                                    </ul>
                                </div> */}

                                
                                <p dangerouslySetInnerHTML={ {__html: data.newsDetail} }></p>
                                

                                {/* <ul className="wp-block-gallery columns-3">
                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img src="/assets/img/blog/blog-4.jpg" />
                                        </figure>
                                    </li>

                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img src="/assets/img/blog/blog-5.jpg" />
                                        </figure>
                                    </li>

                                    <li className="blocks-gallery-item">
                                        <figure>
                                            <img src="/assets/img/blog/blog-6.jpg" />
                                        </figure>
                                    </li>
                                </ul> */}
                                {/* <h3>Four Major Elements That We Offer:</h3>
                                <ul className="features-list">
                                    <li>
                                        <i className="bx bx-check"></i>
                                        Your child’s interests, likes, dislikes
                                    </li>
                                    <li>
                                        <i className="bx bx-check"></i>
                                        Their routines- patterns of eating, sleeping, toileting
                                    </li>
                                    <li>
                                        <i className="bx bx-check"></i>
                                        Your child’s current wellbeing
                                    </li>
                                    <li>
                                        <i className="bx bx-check"></i>
                                        Any major events taking place at home.
                                    </li>
                                </ul> */}
                                {/* <h3>It’s Time To Think Differently About Homeschooling</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> */}
                            </div>

                            {/* <div className="article-footer">
                                <div className="article-tags">
                                    <span>
                                        <i className="bx bxs-bookmark"></i>
                                    </span>
                                    <a href="#">Preschool</a>,
                                    <a href="#">Children</a>
                                </div>

                                <div className="article-share">
                                    <ul className="social">
                                        <li><span>Share:</span></li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i className="bx bxl-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}

                            {/* <div className="post-navigation">
                                <div className="navigation-links">
                                    <div className="nav-previous">
                                        <a href="index.html">
                                            <i className="bx bx-chevron-left"></i>
                                            Prev Post
                                        </a>
                                    </div>
                                    <div className="nav-next">
                                        <a href="index.html">
                                            Next Post 
                                            <i className="bx bx-chevron-right"></i>
                                        </a>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="comments-area">
                                <h3 className="comments-title">3 Comments:</h3>
                            </div> */}
                        </div>
                        
                    }
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <aside className="widget-area">
                            <section className="widget widget_search">
                                <h3 className="widget-title">Search</h3>

                                <form className="search-form">
                                    <label>
                                        <span className="screen-reader-text">Search for:</span>
                                        <input type="search" className="search-field" placeholder="Search..."/>
                                    </label>
                                    <button type="submit">
                                        <i className="bx bx-search-alt"></i>
                                    </button>
                                </form>
                            </section>

                            {/* <section className="widget widget_ketan_posts_thumb">
                                <h3 className="widget-title">Popular Posts</h3>

                                <article className="item">
                                    <a href="#" className="thumb">
                                        <span className="fullimage cover bg1" role="img"></span>
                                    </a>
                                    <div className="info">
                                        <span>June 10, 2020</span>
                                        <h4 className="title usmall"><a href="#">The Data Surrounding Higher Education</a></h4>
                                    </div>
                                </article>

                                <article className="item">
                                    <a href="#" className="thumb">
                                        <span className="fullimage cover bg2" role="img"></span>
                                    </a>
                                    <div className="info">
                                        <span>June 21, 2020</span>
                                        <h4 className="title usmall"><a href="#">Conversion Rate the Sales Funnel Optimization</a></h4>
                                    </div>
                                </article>

                                <article className="item">
                                    <a href="#" className="thumb">
                                        <span className="fullimage cover bg3" role="img"></span>
                                    </a>
                                    <div className="info">
                                        <span>June 30, 2020</span>
                                        <h4 className="title usmall"><a href="#">Business Data is changing the world’s Energy</a></h4>
                                    </div>
                                </article>

                                <article className="item">
                                    <a href="#" className="thumb">
                                        <span className="fullimage cover bg4" role="img"></span>
                                    </a>
                                    <div className="info">
                                        <span>May 10, 2020</span>
                                        <h4 className="title usmall"><a href="#">The Billionaire Guide On Design That Will Get You Rich</a></h4>
                                    </div>
                                </article>

                                <article className="item">
                                    <a href="#" className="thumb">
                                        <span className="fullimage cover bg5" role="img"></span>
                                    </a>
                                    <div className="info">
                                        <span>May 21, 2020</span>
                                        <h4 className="title usmall"><a href="#">The Data-Driven Approach To Understanding Your Users</a></h4>
                                    </div>
                                </article>

                                <article className="item">
                                    <a href="#" className="thumb">
                                        <span className="fullimage cover bg6" role="img"></span>
                                    </a>
                                    <div className="info">
                                        <span>May 30, 2020</span>
                                        <h4 className="title usmall"><a href="#">7 Great Tips For Earn More Money From Digital Industry</a></h4>
                                    </div>
                                </article>
                            </section> */}

                            {/* <section className="widget widget_categories">
                                <h3 className="widget-title">Categories</h3>

                                <ul>
                                    <li><a href="#">Alphabet Parade</a></li>
                                    <li><a href="#">Auditory Processing</a></li>
                                    <li><a href="#">Gross Motor</a></li>
                                    <li><a href="#">Kindergarten</a></li>
                                    <li><a href="#">Games</a></li>
                                    <li><a href="#">Inspire Success</a></li>
                                </ul>
                            </section> */}

                            {/* <section className="widget widget_tag_cloud">
                                <h3 className="widget-title">Popular Tags</h3>

                                <div className="tagcloud">
                                    <a href="#">Preschool</a>
                                    <a href="#">Children</a>
                                    <a href="#">Activities</a>
                                    <a href="#">Educational</a>
                                    <a href="#">Teachers</a>
                                </div>
                            </section> */}
                        </aside>
                    </div>
                </div>
            </div>
        </section>
    )
}
