import React from 'react'

export default function Aboutintro() {
    return (
        
             
        <section className="who-we-are ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="who-we-are-content">
                            <span>About</span>
                            <h3>Learn About Our Work and Cultural Activities</h3>
                            <p>We take a pride in ensuring transparency and accountability through our monitoring and centralized control of key school services, particularly the annual and the mid-year examinations. For all grades, from preschool to matric, our head office team works with a panel of experts to set examination papers and ensures their timely delivery to each branch. We maintain confidentiality of all aspects of the process, and see to it that a fair and merit-based result is prepared for every child.</p>
                            

                            <ul className="who-we-are-list">
                                <li>
                                    <span>1</span>
                                    Homelike Environment
                                </li>
                                <li>
                                    <span>2</span>
                                    Quality Educators
                                </li>
                                <li>
                                    <span>3</span>
                                    Safety and Security
                                </li>
                                <li>
                                    <span>4</span>
                                    Play to Learn
                                </li>
                            </ul>
                            {/* <div className="who-we-are-btn">
                                <a href="#" className="default-btn">Read More</a>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="who-we-are-image-wrap">
                            <img src="assets/img/who-we-are/who-we-are-3.png" alt="image"/>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Who We Are Area --> */}
        </section>
        
        
    )
}
