import React, { Suspense } from 'react'


import { 
  BrowserRouter as Router,
  Switch,
  Route
 } from 'react-router-dom'

//  import $ from 'jquery'

//  import Header           from './components/Header'
//  import Topnavi          from './components/Top_navi'
//  import Footer           from './components/Footer'
//  import ScrollToTop      from './functions/ScrollToTop'
 import Preloader        from './components/Preloader'
//  import Home             from './Home'
  // const Header = React.lazy(() => import('./components/Header'));
  // const Topnavi = React.lazy(() => import('./components/Top_navi'));
  // const Footer = React.lazy(() => import('./components/Footer'));
  // const ScrollToTop = React.lazy(() => import('./functions/ScrollToTop'));
//------------------------------------------ Component ---------------- 
  // const Home = React.lazy(() => import('./Home'));
  // const About = React.lazy(() => {
  //   return new Promise(resolve => setTimeout(resolve, 1 * 1000)).then(
  //     () => import("./About")
  //   );
  // });
  // const Contact = React.lazy(() => {
  //   return new Promise(resolve => setTimeout(resolve, 1 * 1000)).then(
  //     () =>import("./Contact")
  //   );
  // });
  
  // const Events = React.lazy(() => {
  //   return new Promise(resolve => setTimeout(resolve, 1 * 1000)).then(
  //     () => import("./Events")
  //   );
  // });
  // const News = React.lazy(() => {
  //   return new Promise(resolve => setTimeout(resolve, 1 * 1000)).then(
  //     () => import("./News")
  //   );
  // });
  // const Teams = React.lazy(() => {
  //   return new Promise(resolve => setTimeout(resolve, 1 * 1000)).then(
  //     () => import("./Teams")
  //   );
  // });
  // const Apply = React.lazy(() => {
  //   return new Promise(resolve => setTimeout(resolve, 1 * 1000)).then(
  //     () => import("./Apply")
  //   );
  // });
  
  // import('./About'));
  // const Contact   = lazy(() => import('./Contact'));
  // const Events    = lazy(() => import('./Events'));
  // const News      = lazy(() => import('./News'));
  // const Teams     = lazy(() => import('./Teams'));
  // const Apply     = lazy(() => import('./Apply'));
  // const Details     = React.lazy(() => import('./Details'));
//------------------------------------------ Component ---------------- 

 import Header           from './components/Header'
 import Topnavi          from './components/Top_navi'
 import Footer           from './components/Footer'
 import ScrollToTop      from './functions/ScrollToTop'


import Home             from './Home'
import About            from './About'
import Contact          from './Contact'
import Events           from './Events'
import News             from './News'
import Teams            from './Teams'
import Apply            from './Apply'
import Details          from './Details'
import EventDetail      from './EventDetail'

//------------------------------------------ Component ---------------- 

export default function App() {
  
 return (
    <Router>
      <Header></Header>
      <body>
        <Topnavi></Topnavi>  
        <ScrollToTop>
          <Switch>
            <Route exact path="/" render={props => (
                <Suspense fallback={<Preloader/>}>
                  <Home   {...props} />
                  <Footer></Footer>
                </Suspense>
              )}/>
            <Route path="/about"
            render={props => (
              <Suspense fallback={<Preloader/>}>
                <About   {...props} />
                <Footer></Footer>
              </Suspense>
            )}
            />
            <Route path="/contact"
            render={props => (
              <Suspense fallback={<Preloader/>}>
                <Contact   {...props} />
                <Footer></Footer>
              </Suspense>
            )}
            />
            <Route path="/events"
            render={props => (
              <Suspense fallback={<Preloader/>}>
                <Events   {...props} />
                <Footer></Footer>
              </Suspense>
            )}
            />
            <Route path="/news"
            render={props => (
              <Suspense fallback={<Preloader/>}>
                <News   {...props} />
                <Footer></Footer>
              </Suspense>
            )}
            />
            <Route path="/team"
            render={props => (
              <Suspense fallback={<Preloader/>}>
                <Teams   {...props} />
                <Footer></Footer>
              </Suspense>
            )}
            />
            <Route path="/apply-now"
            render={props => (
              <Suspense fallback={<Preloader/>}>
                <Apply   {...props} />
                <Footer></Footer>
              </Suspense>
            )}
            />

            <Route path="/details/:id"
              render={props => (
              <Suspense fallback={<Preloader/>}>
                <Details   {...props} />
                <Footer></Footer>
              </Suspense>
            )}
            />
            <Route path="/event-details/:id"
              render={props => (
              <Suspense fallback={<Preloader/>}>
                <EventDetail   {...props} />
                <Footer></Footer>
              </Suspense>
            )}
            />
              {/* <Home />  
              <Footer></Footer>
            </Route> */}
            {/* <Route path="/about">
              <About />  
              
            </Route> */}
            {/* <Route path="/contact">
              <Contact />  
              <Footer></Footer>
            </Route> */}
            {/* <Route path="/events">
              <Events />  
              <Footer></Footer>
            </Route> */}
            {/* <Route path="/news">
              <News />  
              <Footer></Footer>
            </Route> */}
            {/* <Route path="/team">
              <Teams />  
              <Footer></Footer>
            </Route> */}
            {/* <Route path="/apply-now">
              <Apply />  
              <Footer></Footer>
            </Route> */}
          </Switch>
        </ScrollToTop>
      </body>
     </Router> 
 
  )
}