import React,{useState,useEffect} from 'react'

export default function Footer() {
    const [data,setData]=useState([]);
    const getData=()=>{
        fetch('/data/events.json'
        ,{
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        }
        )
        .then(function(response){
            console.log(response)
            return response.json();
        })
        .then(function(myJson) {
            console.log(myJson);
            setData(myJson)
        });
    }
    useEffect(()=>{
        getData()
    },[])
    return (
        <div>
            {/* <!-- Start Footer Area --> */}
        <section className="footer-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="logo">
                                <h3 >
                                    <a href="">Neoteric Cyber School</a>
                                </h3>
                            </div>
                            <p style={{textAlign:"justify"}}>Neoterics follows the Neo-Montessori system for early education. This system successfully blends all the essential elements of Montessori education into a thematic learning environment. In addition, our philosophy caters to the Multiple Intelligences Theory and Habits of Mind Framework.</p>
                            <ul className="social">
                                <li>
                                    <a href="#" target="_blank">
                                        <i className='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <i className='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <i className='bx bxl-pinterest-alt'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" target="_blank">
                                        <i className='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Contact Us</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bxs-phone'></i>
                                    <span>Phone</span>
                                    <a href="tel:882569756">0123-456-789</a>
                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <span>Email</span>
                                    <a href="mailto:hello@ketan.com">info@neoterics.edu.pk</a>
                                </li>
                                <li>
                                    <i className='bx bx-map'></i>
                                    <span>Address</span>
                                    Hamdard Chowk,Township, Lahore, Pakistan
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Activities</h3>
                            <ul className="quick-links">
                            {
                                data && data.length>0 && data.slice(0, 4).map((item)=>
                                    <li key={item.eventID.toString()}>
                                        <a href="#">{item.title}</a>
                                    </li>
                                
                                )
                            }
                                
                            </ul>
                        </div>
                    </div>

                    {/* <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Photo Gallery</h3>

                            <ul className="photo-gallery-list">
                                <li>
                                    <div className="box">
                                        <img src="assets/img/footer-gallery/footer-gallery-1.jpg" alt="image"/>
                                        <a href="#" target="_blank" className="link-btn"></a>
                                    </div>
                                </li>

                                <li>
                                    <div className="box">
                                        <img src="assets/img/footer-gallery/footer-gallery-2.jpg" alt="image"/>
                                        <a href="#" target="_blank" className="link-btn"></a>
                                    </div>
                                </li>

                                <li>
                                    <div className="box">
                                        <img src="assets/img/footer-gallery/footer-gallery-3.jpg" alt="image"/>
                                        <a href="#" target="_blank" className="link-btn"></a>
                                    </div>
                                </li>

                                <li>
                                    <div className="box">
                                        <img src="assets/img/footer-gallery/footer-gallery-4.jpg" alt="image"/>
                                        <a href="#" target="_blank" className="link-btn"></a>
                                    </div>
                                </li>

                                <li>
                                    <div className="box">
                                        <img src="assets/img/footer-gallery/footer-gallery-5.jpg" alt="image"/>
                                        <a href="#" target="_blank" className="link-btn"></a>
                                    </div>
                                </li>

                                <li>
                                    <div className="box">
                                        <img src="assets/img/footer-gallery/footer-gallery-6.jpg" alt="image"/>
                                        <a href="#" target="_blank" className="link-btn"></a>
                                    </div>
                                </li>

                                <li>
                                    <div className="box">
                                        <img src="assets/img/footer-gallery/footer-gallery-1.jpg" alt="image"/>
                                        <a href="#" target="_blank" className="link-btn"></a>
                                    </div>
                                </li>

                                <li>
                                    <div className="box">
                                        <img src="assets/img/footer-gallery/footer-gallery-2.jpg" alt="image"/>
                                        <a href="#" target="_blank" className="link-btn"></a>
                                    </div>
                                </li>

                                <li>
                                    <div className="box">
                                        <img src="assets/img/footer-gallery/footer-gallery-3.jpg" alt="image"/>
                                        <a href="#" target="_blank" className="link-btn"></a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                 */}
                </div>
            </div>
        </section>
        {/* <!-- End Footer Area --> */}

        {/* <!-- Start Copy Right Area --> */}
        <div className="copyright-area">
            <div className="container">
                <div className="copyright-area-content">
                    <p>
                        Copyright @ 2021 Green Professional Technologies All Rights Reserved by 
                        <a style={{marginLeft: "5px"}} href="https://gptech.pk" target="_blank">
                            Green Professional Technologies
                        </a>
                    </p>
                </div>
            </div>
        </div>
        {/* <!-- End Copy Right Area --> */}

        {/* <!-- Start Go Top Area --> */}
        <div className="go-top">
            <i className='bx bx-up-arrow-alt'></i>
        </div>
        {/* <!-- End Go Top Area --> */}
        
            
            

        </div>
        
    )
}
