import React from 'react'

export default function Homeintro() {
    return (
        <div>
            {/* <!-- Start Who We Are Area --> */}
        <section className="who-we-are ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="who-we-are-image">
                            <img src="assets/img/who-we-are/who-we-are.jpg" alt="image"/>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="who-we-are-content">
                            <span>Who We Are</span>
                            <h3>Academic excellence and learning</h3>
                            <p style={{textAlign: "justify"}} >We have designed our curriculum keeping in mind the rigorous demands of a modern education organized consistent with national desires and global standards. The journey of our students starts with academic excellence by studying major subjects, adequate use of library and digital learning. We pursue the goal of maximum development of our students’ intellectual capacities and skills required in the contemporary world.</p>
                            
                            <ul className="who-we-are-list">
                                <li>
                                    <span>1</span>
                                    Homelike Environment
                                </li>
                                <li>
                                    <span>2</span>
                                    Quality Educators
                                </li>
                                <li>
                                    <span>3</span>
                                    Safety and Security
                                </li>
                                <li>
                                    <span>4</span>
                                    Play to Learn
                                </li>
                            </ul>
                            {/* <div className="who-we-are-btn">
                                <a href="#" className="default-btn">Read More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="who-we-are-shape">
                <img src="assets/img/who-we-are/who-we-are-shape.png" alt="image"/>
            </div>
        </section>
        {/* <!-- End Who We Are Area --> */}
        </div>
    )
}
