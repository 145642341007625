import React from 'react'

import PageBanner       from './components/PageBanner'
import EventsArea       from './components/EventsArea'
import Newsletter       from './components/Newsletter'

export default function Events() {
    return (
        <div>
            <PageBanner name="Events" />
            <EventsArea/>
            <Newsletter/>
        </div>
    )
}