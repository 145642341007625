import React from 'react'

import PageBanner       from './components/PageBanner'
import TeamsArea       from './components/TeamsArea'
import Newsletter       from './components/Newsletter'
import Testimonials       from './components/Testimonials'

export default function Teams() {
    return (
        <div>
            <PageBanner name="Teams" />
            <TeamsArea/>
            <Testimonials/>
            <Newsletter/>
        </div>
    )
}