import React from 'react'
import {Link} from 'react-router-dom'

export default function Request() {
    return (
        <div>
            {/* <!-- Start Choose Area --> */}
        <section className="choose-area pt-100 pb-70">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="single-choose-item">
                            <span>Courses</span>
                            <h3>Request a Tutors For Free Online Class</h3>
                            <p>CHOOSING THE RIGHT SCHOOL IS IMPORTANT AND WE WANT TO DO ALL WE CAN TO HELP YOU DECIDE WHETHER NGS PRESCHOOL IS RIGHT FOR YOUR CHILD.</p>
                            

                            <div className="choose-btn">
                                <Link to="/apply-now" className="default-btn">Apply Now</Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="choose-image">
                            <img src="assets/img/choose/choose-1.png" alt="image"/>

                            <div className="choose-image-shape">
                                <div className="shape-1">
                                    <img src="assets/img/choose/choose-shape-1.png" alt="image"/>
                                </div>
                                <div className="shape-2">
                                    <img src="assets/img/choose/choose-shape-2.png" alt="image"/>
                                </div>
                                <div className="shape-3">
                                    <img src="assets/img/choose/choose-shape-3.png" alt="image"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- End Choose Area --> */}
        </div>
    )
}
